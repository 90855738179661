import * as React from "react";
import { css } from "@emotion/react";
import {
  fontSize,
  fontWeight,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import { textColor } from "main/javascripts/styles/base/colorStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";

export interface IProps {
  children: React.ReactNode;
  styles?: any;
}

export const FieldDescription: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> => {
  return <div css={[blockStyle, props.styles]}>{props.children}</div>;
};

const blockStyle = css`
  font-size: ${fontSize.caption};
  line-height: ${lineHeight.caption1};
  font-weight: ${fontWeight.regular};
  color: ${textColor.secondaryDarkColor};
  display: block;
  padding: 0 0 ${space.atom};
`;
